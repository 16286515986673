// Navbar.js
import React from 'react';
import logo from '../images/logo.svg';
import '../components/NavBar';

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="logo-section">
        <img src={logo} alt="Hot Pod Saunas Logo" className="navbar-logo" width='200px' height='200px' />
      </div>
      {/* You can add more navigation links here if needed */}
    </nav>
  );
};

export default Navbar;
