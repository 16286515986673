import React, { useState, useEffect } from 'react';
import '../style/Home.css'; // Make sure to create a corresponding CSS file to style your component
import CheckBoxSlider from '../components/CheckBoxSlider';
import TextTransition, { presets } from 'react-text-transition';
import axios from 'axios';
import { analytics } from '../firebase';


const HomePage = () => {
    const [email, setEmail] = useState('');
    const [isRent, setIsRent] = useState(true);
    const [text, setText] = useState('coming to sauna events');
    const [isLocked, setIsLocked] = useState(false);
    const [marketingConsent, setMarketingConsent] = useState(false);


    useEffect(() => {
        if (analytics) {
            console.log(analytics);
            try {
                analytics.logEvent('page_visit');
            } catch (error) {
                console.error('Error logging event:', error);
            }
        }
    }, []);

    // ... (existing functions)

    const handleMarketingConsentChange = (event) => {
        setMarketingConsent(event.target.checked);
    };

    const changeIsRent = () => {
        if (!isLocked) {
            setIsRent(!isRent);
            setIsLocked(true);
            setTimeout(() => {
                setIsLocked(false);
            }, 1000); // 1 second lock
            toggleText();
        }
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const toggleText = () => {
        setText(text === 'coming to sauna events' ? 'renting for my events' : 'coming to sauna events');
    };

    // const changeIsRent = () => {
    //     setIsRent(!isRent);

    // }

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('Handling submit');
        // Prepare the data to be sent
        const dataToSend = {
            email: email,
            message: isRent ? 'renting for my events' : 'coming to sauna events',
            marketingConsent: marketingConsent, // new field
            source_url: 'https://hotpodsaunas.co.uk' // Replace with your actual URL
        };

        try {
            // Replace 'YOUR_CLOUD_FUNCTION_URL' with the actual URL of your cloud function
            // const response = await axios.post('http://127.0.0.1:5001/savage-software-consulting/us-central1/client_query', dataToSend);
            const response = await axios.post('https://client-query-delhcxb7aa-uc.a.run.app', dataToSend);

            console.log('Response:', response.data);
            setEmail('');
            // You can do more here, like showing a success message
            if (analytics) {
                console.log(analytics);
                try {
                    analytics.logEvent('form_submission', {
                        form_name: 'contact_form',
                        submission_time: new Date().toISOString() // Example parameter
                    });
                } catch (error) {
                    console.error('Error logging event:', error);
                }
            }
        } catch (error) {
            console.error('Error:', error);
            // Handle error, e.g., show an error message
        }
        console.log('Finished submission');
    };


    return (
        <div className="homepage">
            <div className="headline-section">
                <h1 className='brown-font'>Secure Your Spot for Our Mobile Sauna Experience</h1>
                <h2 className="coming-soon light-brown-font">Coming Spring '24</h2>
            </div>
            <div className="cta-section">
                <label className='cta-label' htmlFor="email-input">
                    I am interested in&nbsp;
                    <TextTransition springConfig={presets.gentle} className='light-brown-font'>
                        {text}
                    </TextTransition>
                </label>
                {/* <button onClick={handleSubmit} className="submit-button">I'M INTERESTED</button> */}
                <div className='flex-column medium-padding'>
                    <CheckBoxSlider name='rent' checked={isRent} onChange={changeIsRent} />
                </div>
                <div className='flex-column'>
                    <input
                        type="email"
                        id="email-input"
                        placeholder="Enter your email"
                        value={email}
                        onChange={handleEmailChange}
                        className="email-input medium-margin medium-padding"
                    />
                    <label>
                        <input
                            type="checkbox"
                            checked={marketingConsent}
                            onChange={handleMarketingConsentChange}
                        />
                        I agree to receive marketing emails from Hot Pod Saunas.
                    </label>
                    <button onClick={handleSubmit} className='cta-button medium-margin'>I'm interested!</button>
                </div>
            </div>
        </div>
    );
};

export default HomePage;
