// import React from 'react';
// import '../style/CheckBoxSlider.css';

// const CheckBoxSlider = ({ name, checked, onChange }) => {
//     console.log('name: ', name);
//     console.log('checked: ', checked);
//     console.log('onChange: ', onChange);
//     return (
//         <div className="checkbox-slide-wrapper">
//             <input
//                 type="checkbox"
//                 className="checkbox-slide"
//                 id={`checkbox-slide-${name}`}
//                 name={name}
//                 checked={checked}
//                 onChange={onChange}
//             />
//             <label for="checkbox-slide"></label>
//         </div>)
// }

// export default CheckBoxSlider;

import React from 'react';
// import '../style/PostSignUp.css';
import '../style/CheckBoxSlider.css';

const CheckBoxSlider = ({ name, checked, onChange }) => {
    return (
        <div className="checkbox-slide-wrapper">
            <input
                type="checkbox"
                className="checkbox-slide"
                id={`checkbox-slide-${name}`}
                name={name}
                checked={checked}
                onChange={onChange}
            />
            <label htmlFor={`checkbox-slide-${name}`}></label> {/* Match label htmlFor with input ID */}
        </div>
    );
};

export default CheckBoxSlider;