// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDdnUDFMOXML1vWMfzjGR79PklXlto3LdQ",
    authDomain: "savage-software-consulting.firebaseapp.com",
    projectId: "savage-software-consulting",
    storageBucket: "savage-software-consulting.appspot.com",
    messagingSenderId: "416269917813",
    appId: "1:416269917813:web:31377867bb8d6cba0f223c",
    measurementId: "G-C0TFHXJ8KZ"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);
app.automaticDataCollectionEnabled = true;

let analytics;
if (typeof window !== 'undefined') {
    // Initialize Firebase Analytics only in a client-side environment
    analytics = getAnalytics(app);
}

export { analytics, app };